.block-wrapper {

}

.block-wrapper-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.block-title {
    font-size: 1.2em;
    font-weight: bolder;
}

.block-description {
    font-size: 0.9em;
    opacity: 0.7;
}

.block-close {
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.block-back {
    cursor: pointer;
}