.editable-wrap {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.editable-block {
    width: 100%;
}

.editable-placeholder {
    opacity: 0.8;
    font-style: italic;
}