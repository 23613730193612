.app-root {
    margin-top: 12px;
}

.pagination-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pagination-hint {
    font-size: 0.8em;
    opacity: 0.8;
}