.task-list-params {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.interrupt-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.interrupt-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.interrupt-success-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.task-param-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}