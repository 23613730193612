.app-tab-right-content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.app-tab-content {
    padding: 8px;
    background: white;
    border-radius: 8px;
}

.app-tab-content-wrapper {
    margin-top: 8px;
}

.app-tab-content-footer {
    opacity: 0.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
}

.app-tab-left-nav {
}

.app-tab-left-nav-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.app-tabs {
    gap: 4px;
}