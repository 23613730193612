.table-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.table-toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-top: 8px;
    flex-wrap: wrap;
}

.icon-button {
    display: flex;
    gap: 4px;
    align-items: center;
}

.bot-cred-input {
    width: 100%;
}

.bot-create-table-cell {
    text-align: center;
    vertical-align: middle;
}

.bot-create-table-cell-end {
    text-align: end;
}

.modal-table {
    max-height: 200px;
    overflow: scroll;
}

.create-error {
    margin-top: 12px;
}
